import React, { memo, useEffect, useMemo, useState } from "react";
import { useMyPatient } from "../pages/Mypatient/custom-hook/my-patient-hook";
import { Link } from "react-router-dom";
import Modals from "./modal";
import pdfIcon from "../assets/img/pdf-img.png";
import docIcon from "../assets/img/doc-logo.png";
import RecordNotfound from "./recorderror";
import apissss from "../services/api/new-journey-api";
import "../assets/css/my-document.css";
import ToastNotification from "./toast-notification";

const MyPatientDocuments = ({
  imgPath,
  document,
  showDocTab,
  channels,
  messages,
  journeyList,
  changeDocumentStateAPI,
  showJourneyStateToast,
  setShowJourneyStateToast,
  journeyStateToastMsg,
  documentSearchTerm,
  setDocumentSearchTerm,
}) => {
  // console.log("MyPatientDocuments component rendered");
  // console.log("journeyList", journeyList);
  console.log("messagess", messages);
  const [modalShow, setModalShow] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [extension, setExtension] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [defautJourneyState, setDefaultJourneyState] = useState({});
  const [pubnubDefautJourneyState, setPubnubDefaultJourneyState] = useState({});

  /*SEARCH FUNCTIONALITY */
  useEffect(() => {
    const results = handleSearch(documentSearchTerm);
    setSearchResult(results);
  }, [documentSearchTerm]);

  const handleSearch = (term) => {
    if (document) {
      const filteredArr = document.filter((item) => {
        return (
          item.shared_by.name.toLowerCase().includes(term.toLowerCase()) ||
          item.procedure?.procedure.toLowerCase().includes(term.toLowerCase())
        );
      });
      return filteredArr;
    }
  };

  const handleModal = (img, document_extension) => {
    setImgUrl(img);
    setExtension(document_extension);
    setModalShow(true);
  };

  const value = useMemo(() => {
    let val = {};
    if (document) {
      for (const element of document) {
        let defaultValues = element.patient_journey
          ? element.patient_journey.journey_data?.id
          : "";
        val[element.id] = defaultValues;
      }
      // console.log("val", val)
      setDefaultJourneyState(val);
    }
  }, [document]);

  const handleJourneyState = (e, id) => {
    e.preventDefault();
    setDefaultJourneyState({ ...defautJourneyState, [id]: e.target.value });
    let param = { document_id: id, journey_id: e.target.value };
    changeDocumentStateAPI(param, id, e.target.value);

    // console.log("journeyId", e.target.value);
    // window.document.getElementById(`journey_state_dropdown_${id}`).value =e.target.value;
  };

  const pubnubValue = useMemo(() => {
    // console.log("running");
    let val = {};
    let val1 = {}; ///new code
    if (messages) {
      for (const element of messages) {
        // console.log("element", element);
        if (element.message.uploadedImages?.length > 0) {
          for (const item of element.message.uploadedImages) {
            // console.log("item1", item);
            let defaultValues = item.journey_id ? item.journey_id : "";
            val[item.document_id] = defaultValues;
          }
        } else {
          // console.log("else running")
          let defaultValues = element.message.journey_id
            ? element.message.journey_id
            : "";
          val[element.message.document_id] = defaultValues;
          val1[element.message.document_id] = defaultValues; ///new code
        }
      }
      // console.log("val1", val);
      setPubnubDefaultJourneyState(val);
      setDefaultJourneyState({ ...defautJourneyState, ...val1 }); ///new code
    }
  }, [messages]); ///handlePubNubJourneyState

  const handlePubNubJourneyState = (e, id) => {
    e.preventDefault();
    setPubnubDefaultJourneyState({
      ...pubnubDefautJourneyState,
      [id]: e.target.value,
    });
    let param = { document_id: id, journey_id: e.target.value };
    changeDocumentStateAPI(param, id, e.target.value);

    // console.log("journeyId", e.target.value);
    // window.document.getElementById(`journey_state_dropdown_${id}`).value =e.target.value;
  };

  // useEffect(() => {
  //   console.log("defautJourneyState", defautJourneyState);
  // }, [defautJourneyState]);

  // useEffect(() => {
  //   console.log("pubnubDefautJourneyState", pubnubDefautJourneyState);
  // }, [pubnubDefautJourneyState]);

  return (
    <div
      className={showDocTab ? "tab-pane fade active show" : "tab-pane fade"}
      id="pills-contact"
      role="tabpanel"
      aria-labelledby="pills-contact-tab"
    >
      {modalShow && imgUrl ? (
        <Modals
          show={modalShow}
          imgUrl={imgUrl}
          onHide={() => setModalShow(false)}
          docExtension={extension}
        />
      ) : null}
      {showJourneyStateToast ? (
        <ToastNotification
          content={journeyStateToastMsg}
          setShow={setShowJourneyStateToast}
          show={showJourneyStateToast}
        />
      ) : null}
      <div className="col-12">
        {document && document.length > 0 ? (
          <div className="row top_section mv__top_section">
            <div className="col-md-6 mt-4">
              <div className="searchicon_main">
                <input
                  type="text"
                  value={documentSearchTerm}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Search Document"
                  onChange={(e) => setDocumentSearchTerm(e.target.value)}
                />
                <i
                  className="fa fa-search"
                  style={{
                    fontSize: "15px",
                    color: "#a7a7a7",
                    position: "absolute",
                    top: "10px",
                    left: "12px",
                  }}
                ></i>
              </div>
            </div>
            <div className="col-md-12">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="table_wrapper table-responsive">
                    <table className="table My_table">
                      <thead>
                        <tr className="table_heading">
                          <th scope="col" className="document-th">
                            Document
                          </th>
                          <th scope="col" className="document-th">
                            OD
                          </th>
                          <th scope="col" className="document-th">
                            Procedure
                          </th>
                          <th scope="col" className="document-th">
                            Shared on
                          </th>
                          <th scope="col" className="document-th">
                            Journey State
                          </th>
                          <th
                            className="action-th-width  document-th"
                            scope="col"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {messages.length > 0 && !documentSearchTerm ///change_document_journey_state //&& item.message.type == "change_document_journey_state" //add_documents
                          ? messages.map((item, index) => {
                              console.log("lll", item);
                              if (
                                item.channel === channels[0] &&
                                item.message.type == "add_documents"
                              ) {
                                return (
                                  <>
                                    {item.message.uploadedImages?.map(
                                      (newImg, index) => {
                                        console.log("newimg", newImg);
                                        return (
                                          <tr key={index}>
                                            <td className="patiend-photo-table-td">
                                              <div className="report_img">
                                                {newImg.extension === "doc" ||
                                                newImg.extension === "docx" ? (
                                                  <img src={docIcon} alt="" />
                                                ) : (
                                                  <img
                                                    src={
                                                      newImg.extension === "pdf"
                                                        ? pdfIcon
                                                        : newImg.url
                                                    }
                                                    alt=""
                                                  />
                                                )}
                                              </div>
                                            </td>
                                            <td>{newImg.shared_by}</td>
                                            <td>N/A</td>
                                            <td>{newImg.created_at}</td>
                                            <td>{newImg?.journey_name}</td>
                                            {/* <td>
                                              {
                                                pubnubDefautJourneyState[
                                                  newImg.document_id
                                                ]
                                              }
                                            </td> */}
                                            {/* <td>
                                              <select
                                                className="form-select Surgery_Scheduled "
                                                value={
                                                  pubnubDefautJourneyState[
                                                    newImg.document_id
                                                  ]
                                                }
                                                onChange={(e) =>
                                                  handlePubNubJourneyState(
                                                    e,
                                                    newImg.document_id
                                                  )
                                                }
                                              >
                                                <option value="">
                                                  Please Select Journey State
                                                </option>
                                                {journeyList &&
                                                  journeyList.map(
                                                    (item, index) => {
                                                      const { journey_data } =
                                                        item;
                                                      // console.log("item", item)
                                                      return (
                                                        <React.Fragment
                                                          key={index}
                                                        >
                                                          <option
                                                            value={`${journey_data.id}`}
                                                          >
                                                            {journey_data.title}
                                                          </option>
                                                        </React.Fragment>
                                                      );
                                                    }
                                                  )}
                                              </select>
                                            </td> */}
                                            <td>
                                              <div className="table_icon ">
                                                <div>
                                                  {/* <a className="" href="/"> */}
                                                  {/* <Link to="" onClick={() => handleModal(document_path)}> */}
                                                  <i
                                                    className="fa fa-eye"
                                                    style={{
                                                      fontSize: "17px",
                                                      color:
                                                        "rgba(38, 50, 56, 1)",
                                                    }}
                                                    onClick={() =>
                                                      handleModal(
                                                        newImg.url,
                                                        newImg.extension
                                                      )
                                                    }
                                                  />
                                                  {/* </Link> */}
                                                  {/* </a> */}
                                                </div>
                                                <div>
                                                  {/* <Link to=""  onClick={(e)=>handleDownload(e,document_path)}> */}
                                                  <a
                                                    href={
                                                      newImg.url_for_download
                                                    }
                                                    download
                                                  >
                                                    <i
                                                      className="fa fa-download"
                                                      style={{
                                                        fontSize: "17px",
                                                        color:
                                                          "rgba(38, 50, 56, 1)",
                                                      }}
                                                    />
                                                  </a>
                                                  {/* </Link> */}
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                  </>
                                );
                              } else {
                                return null;
                              }
                            })
                          : null}
                        {documentSearchTerm.length > 0 ? (
                          searchResult.length > 0 ? (
                            searchResult.map((doc, index) => {
                              const {
                                document_path,
                                document_download_path,
                                document_name,
                                created_at,
                                document_extension,
                                shared_by,
                                procedure,
                                patient_journey,
                              } = doc;
                              // console.log("newwwww", doc);
                              // const { journey_data } = patient_journey;
                              return (
                                <tr key={index}>
                                  {/* <tr> */}
                                  <td className="patiend-photo-table-td">
                                    <div className="report_img">
                                      {document_extension === "doc" ||
                                      document_extension === "docx" ? (
                                        <img src={docIcon} alt="" />
                                      ) : (
                                        // <p className="ps-2 mt-1">
                                        //   {document_extension}
                                        // </p>
                                        <img
                                          src={
                                            document_extension === "pdf"
                                              ? pdfIcon
                                              : document_path
                                          }
                                          // src="https://images.examples.com/wp-content/uploads/2017/04/Medical-Condition-Report1.jpg"
                                          alt=""
                                        />
                                      )}
                                    </div>
                                  </td>
                                  <td>{shared_by?.name}</td>
                                  <td>
                                    {procedure ? procedure.procedure : "N/A"}
                                  </td>
                                  <td>{created_at}</td>
                                  <td>
                                    {patient_journey
                                      ? patient_journey.journey_data?.title
                                      : "N/A"}
                                  </td>
                                  {/* <td>
                                    <select
                                      className="form-select Surgery_Scheduled "
                                      aria-label="Default select example"
                                      value={defautJourneyState[doc.id]}
                                      onChange={(e) =>
                                        handleJourneyState(e, doc.id)
                                      }
                                    >
                                      <option value="">
                                        Please Select Journey State
                                      </option>
                                      {journeyList &&
                                        journeyList.map((item, index) => {
                                          const { journey_data } = item;
                                          // console.log(
                                          //   "journey_data",
                                          //   journey_data
                                          // );
                                          return (
                                            <React.Fragment key={index}>
                                              <option
                                                value={`${journey_data.id}`}
                                              >
                                                {journey_data.title}
                                              </option>
                                            </React.Fragment>
                                          );
                                        })}
                                    </select>
                                  </td> */}
                                  <td>
                                    <div className="table_icon ">
                                      <div>
                                        {/* <a className="" href="/"> */}
                                        {/* <Link to="" onClick={() => handleModal(document_path)}> */}
                                        <i
                                          className="fa fa-eye"
                                          style={{
                                            fontSize: "17px",
                                            color: "rgba(38, 50, 56, 1)",
                                          }}
                                          onClick={() =>
                                            handleModal(
                                              document_path,
                                              document_extension
                                            )
                                          }
                                        />
                                        {/* </Link> */}
                                        {/* </a> */}
                                      </div>
                                      <div>
                                        {/* <Link to=""  onClick={(e)=>handleDownload(e,document_path)}> */}
                                        <a
                                          href={document_download_path}
                                          download
                                        >
                                          <i
                                            className="fa fa-download"
                                            style={{
                                              fontSize: "17px",
                                              color: "rgba(38, 50, 56, 1)",
                                            }}
                                          />
                                        </a>
                                        {/* </Link> */}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan={6}
                                style={{ borderBottom: "0px" }}
                                className="mv__norecord"
                              >
                                <RecordNotfound heading="Searched Documents are not found.." />
                              </td>
                            </tr>
                          )
                        ) : (
                          document &&
                          document.map((doc, index) => {
                            const {
                              document_path,
                              document_download_path,
                              document_name,
                              created_at,
                              document_extension,
                              shared_by,
                              procedure,
                              patient_journey,
                            } = doc;
                            // console.log("newwwww", doc);
                            // const { journey_data } = patient_journey;
                            // console.log(procedure);
                            // let defaultJourney =doc.patient_journey? doc.patient_journey.journey_data?.id : "" ;
                            // setDefaultJourneyState({...defautJourneyState, [doc.id] : defaultJourney})

                            // setDefaultJourneyState(defaultJourney)
                            return (
                              <tr key={index}>
                                {/* <tr> */}
                                <td className="patiend-photo-table-td">
                                  <div className="report_img">
                                    {document_extension === "doc" ||
                                    document_extension === "docx" ? (
                                      <img src={docIcon} alt="" />
                                    ) : (
                                      // <p className="ps-2 mt-1">
                                      //   {document_extension}
                                      // </p>
                                      <img
                                        src={
                                          document_extension === "pdf"
                                            ? pdfIcon
                                            : document_path
                                        }
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </td>
                                <td>{shared_by?.name}</td>
                                <td>
                                  {procedure ? procedure.procedure : "N/A"}
                                </td>
                                <td>{created_at}</td>
                                <td>{patient_journey?patient_journey.journey_data?.title : "N/A"}</td>
                                {/* <td>
                                  <select
                                    className="form-select Surgery_Scheduled "
                                    // aria-label="Default select example"
                                    // id={`journey_state_dropdown_${doc.id}`}
                                    value={defautJourneyState[doc.id]}
                                    onChange={(e) =>
                                      handleJourneyState(e, doc.id)
                                    }
                                  >
                                    <option value="">
                                      Please Select Journey State
                                    </option>
                                    {journeyList &&
                                      journeyList.map((item, index) => {
                                        const { journey_data } = item;
                                        // console.log(
                                        //   "journey_data",
                                        //   journey_data
                                        // );
                                        return (
                                          <React.Fragment key={index}>
                                            <option
                                              value={`${journey_data.id}`}
                                            >
                                              {journey_data.title}
                                            </option>
                                          </React.Fragment>
                                        );
                                      })}
                                  </select>
                                </td> */}
                                <td>
                                  <div className="table_icon ">
                                    <div>
                                      {/* <a className="" href="/"> */}
                                      {/* <Link to="" onClick={() => handleModal(document_path)}> */}
                                      <i
                                        className="fa fa-eye"
                                        style={{
                                          fontSize: "17px",
                                          color: "rgba(38, 50, 56, 1)",
                                        }}
                                        onClick={() =>
                                          handleModal(
                                            document_path,
                                            document_extension
                                          )
                                        }
                                      />
                                      {/* </Link> */}
                                      {/* </a> */}
                                    </div>
                                    <div>
                                      {/* <Link to=""  onClick={(e)=>handleDownload(e,document_path)}> */}
                                      <a href={document_download_path} download>
                                        <i
                                          className="fa fa-download"
                                          style={{
                                            fontSize: "17px",
                                            color: "rgba(38, 50, 56, 1)",
                                          }}
                                        />
                                      </a>
                                      {/* </Link> */}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <RecordNotfound heading="Documents not found" />
        )}
      </div>
    </div>
  );
};
export default memo(MyPatientDocuments);
