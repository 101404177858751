import { callAPI } from "../config/api-core";
import { existingPatientsAPIMethods } from "../methods/existing-patients-api-method";

const existingPatientsFetch = async () => {
  const response = await callAPI(
    existingPatientsAPIMethods,
    true,
    {},
    {},
    false,
    false
  );
  return response;
};

export const getExistingPatientsAPI = () => existingPatientsFetch();
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getExistingPatientsAPI,
};
