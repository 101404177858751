import { callAPI } from "../config/api-core";
import { logouts } from "../methods/logout-api-method";

const logoutAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let token = JSON.parse(userToken);
  let header = {
    // "content-type": "multipart/form-data",
    Authorization: userToken && `Bearer ${token}`,
  };
  // console.log("hiii")
  const response = await callAPI(logouts, false, request, header, false, false);
  return response;
};

export const logoutApi = (request) => logoutAPI(request);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  logoutApi,
};
