import { Toast, ToastContainer } from "react-bootstrap";
import "../assets/css/toast.css";

const ToastNotification = ({ setShow, show, content }) => {
  return (
    <div className="toast-div" style={{ width: "100%" }}>
      <ToastContainer className="p-3 " style={{ width: "100%" }}>
        <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Body>{content}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};
export default ToastNotification;
