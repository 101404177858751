import { callAPI } from "../config/api-core";
import { chatActivityAPIMethods } from "../methods/chat-activity-api-method";

const chatActivityAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let token = JSON.parse(userToken);
  let header = {
    "Content-Type": "application/json",
    Authorization: userToken && `Bearer ${token}`,
  };
  const response = await callAPI(
    chatActivityAPIMethods,
    false,
    request,
    header,
    false,
    false
  );
  return response;
};

export const chatActivity = (request) => chatActivityAPI(request);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  chatActivity,
};
