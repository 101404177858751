import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Pagination = ({
  paginate,
  totalRecord,
  currentPage,
  handleNextbtn,
  handlePrevbtn,
  maxPageNumberLimit,
  minPageNumberLimit,
}) => {
  const [pageNumbers, setPageNumber] = useState([]);
  let limit = 6;

  useEffect(() => {
    setPageNumbers();
  }, [Math.ceil(totalRecord / limit)]);

  const setPageNumbers = () => {
    let pageNumber = [];
    let x = 0;
    for (let i = 1; i <= Math.ceil(totalRecord / limit); i++) {
      pageNumber[x] = i;
      setPageNumber(pageNumber);
      x++;
    }
  };
  // console.log(pageNumbers);
  // console.log("page", currentPage);
  return (
    <div className="pagination_wrapper">
      <nav aria-label="...">
        {pageNumbers && pageNumbers.length > 0 ? (
          <ul className="pagination pagination_card">
            {currentPage && currentPage > 1 ? (
              <li className="page-item ">
                <Link
                  to=""
                  className="page-link"
                  // href="/"
                  // tabindex="-1"
                  // aria-disabled="true"
                  onClick={() => handlePrevbtn()}
                >
                  Previous
                </Link>
              </li>
            ) : (
              ""
            )}
            <>
              {pageNumbers.length > 1 &&
                pageNumbers.map((number, index) => {
                  // console.log(number, minPageNumberLimit);
                  if (
                    number < maxPageNumberLimit + 1 &&
                    number > minPageNumberLimit
                  ) {
                    // console.log(number, maxPageNumberLimit);
                    return (
                      <li className="page-item " key={index}>
                        <Link
                          to=""
                          onClick={() => paginate(number)}
                          className={`page-link ${
                            currentPage == number ? "active_button" : ""
                          }`}
                        >
                          {number}
                        </Link>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
            </>
            <>
              {currentPage &&
              currentPage >= 3 &&
              currentPage !== pageNumbers[pageNumbers.length - 1] ? (
                <li className="page-item">
                  <Link
                    className="page-link link_next"
                    href="/"
                    onClick={() => handleNextbtn()}
                  >
                    Next
                  </Link>
                </li>
              ) : (
                ""
              )}
            </>

            {/* <li className="page-item active">
              <a className="page-link" href="/">
                1
              </a>
            </li>
            <li className="page-item" aria-current="page">
              <a className="page-link active_button" href="/">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="/">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link link_next" href="/">
                Next
              </a>
            </li> */}
          </ul>
        ) : (
          ""
        )}
      </nav>
    </div>
  );
};
export default Pagination;

// const Pagination = ({ totalPages, fetchData }) => {
//   const [currentPage, setCurrentPage] = useState(1);

//   const handlePageClick = pageNumber => {
//   setCurrentPage(pageNumber);
//   fetchData(pageNumber);
//   };

//   return (
//   <div>
//   {Array.from({ length: totalPages }, (_, index) => (
//   <button
//   key={index + 1}
//   onClick={() => handlePageClick(index + 1)}
//   disabled={currentPage === index + 1}
//   >
//   {index + 1}
//   </button>
//   ))}
//   </div>
//   );
//   };
