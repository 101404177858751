import Dropdown from "react-bootstrap/Dropdown";
import Profileicon from "../assets/img/profile-pic.png";
import "../assets/css/logout.css";
import img from "../assets/img/logout-icon.svg";
import { useLogin } from "../pages/login/custom-hook/login-hook";

function Logoutbtn({handleShow}) {
  const {handleLogout} = useLogin()
  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="success"
        className="dropdown-btnn"
        id="dropdown-basic"
        style={{
          background: "none",
          border: "none",
          boxShadow: "none !important",
        }}
      >
        <a className="nav-link " href="/">
          <div className="Newheader-content">
            {/* <div className="Newheader-img-holder">
                <img src={Profileicon} alt="" ></img>
            </div> */}
            {/* <i className="fa fa-chevron-down icon-btnn"></i> */}
          </div>
        </a>
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <Dropdown.Item  className="dropdown-content-items" onClick={handleShow}>
          {" "}
          <i className="fa fa-user" style={{ marginRight: "12px", color: "#33b0ce" }}/>
          {/* <img src={img} alt="" style={{ marginRight: "12px" }} /> */}
          Profile
        </Dropdown.Item>
        <Dropdown.Item  className="dropdown-content-items" onClick={handleLogout}>
          {" "}
          {/* <img src={img} alt="" style={{ marginRight: "12px" }} /> */}
          <i className="fa fa-sign-out" style={{ marginRight: "12px", color: "#33b0ce" }}/>
          Logout{" "}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Logoutbtn;
