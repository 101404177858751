import { callAPI } from "../config/api-core";
import { patientJourneyStateListAPIMethods } from "../methods/patient-journey-state-list-api-method";

const patientJourneyStateListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let token = JSON.parse(userToken);
  let header = {
    // "content-type": "multipart/form-data",
    Authorization: userToken && `Bearer ${token}`,
  };
  const response = await callAPI(
    patientJourneyStateListAPIMethods,
    false,
    request,
    header,
    false,
    false
  );
  return response;
};

export const patientJourneyStateList = (request) => patientJourneyStateListAPI(request);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  patientJourneyStateList,
};
