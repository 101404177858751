import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../pages/login";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "../pages/Dashboard";
import Documents from "../pages/Documents";
import Mypatient from "../pages/Mypatient";
import Notification from "../pages/Notification";
import Newpatient from "../pages/Referralnewpatient";
import PublicRoute from "./PublicRoute";
import PageNotFound from "../pages/no-page";
import ExistingPatientPost from "../pages/existing-patient";
import ForgotPassword from "../pages/forgot-password";
import ChangePassword from "../pages/change-password";

const RouteIndex = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route path="/change-pwd" element={<ChangePassword />} />
        <Route path="/forgot-pwd" element={<ForgotPassword />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route exact path="/documents" element={<Documents />} />
          <Route path="/mypatient" element={<Mypatient />}>
            <Route path=":patientName" element={<Mypatient />} />
          </Route>
          <Route exact path="/notification" element={<Notification />} />
          <Route exact path="/newpatient" element={<Newpatient />} />
          <Route exact path="/existing-patient-post" element={<ExistingPatientPost />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  ); 
};
export default RouteIndex;
