import { callAPI } from "../config/api-core";
import { documentsAPIMethods } from "../methods/document-api-method";

const documentsListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let token = JSON.parse(userToken);
  let header = {
    "content-type": "multipart/form-data",
    Authorization: userToken && `Bearer ${token}`,
  };
  const response = await callAPI(
    documentsAPIMethods,
    false,
    request,
    header,
    false,
    false
  );
  return response;
};

export const documentsList = (request) => documentsListAPI(request);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  documentsList,
};
