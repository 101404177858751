import * as Yup from "yup";

export const validation = Yup.object().shape({
  patient_name: Yup.string() .matches(/^[A-Za-z ]*$/, 'Please enter valid name').required(" Patient Name is required").max(20, 'Maximum 20 characters allowed'),
  phone_number: Yup.number()
    // .typeError("That doesn't look like a phone number")
    // .positive("A phone number can't start with a minus")
    // .integer("A phone number can't include a decimal point")
    .required("Phone Number is required"),
  // procedure: Yup.string().required(" procedure field required"),
  // documents: Yup.array()
  //   .of(
  //     Yup.mixed()
  //       .test(
  //         "fileFormat",
  //         "Invalid file format",
  //         (value) =>
  //           value &&
  //           (value.type === "image/jpeg" ||
  //             value.type === "image/jpg" ||
  //             value.type === "image/png")
  //       )
  //       .required("File is required")
  //   )
  //   .min(1, "At least one file is required")
  //   .required("At least one file is required"),
});
