import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api/new-password-api";

export const useChangePwd = () => {
  const [isRevaled1, setIsRevaled1] = useState(false);
  const [isRevaled2, setIsRevaled2] = useState(false);
  const [apiError, setApiError] = useState("");

  const navigate = useNavigate();

  const hideshow1 = () => {
    setIsRevaled1(!isRevaled1);
  };

  const hideshow2 = () => {
    setIsRevaled2(!isRevaled2);
  };

  const requestNewPassword = async(request) => {
    try {
      const response = await api.newPasswordFetch(request);
      // console.log("Response", response);
      if(response && response.data.status == 1){
        // console.log("navigating to login");
        navigate("/")
      }else{
        setApiError( response.data.message)
      }
    } catch (err) {
      console.log(err);
    }
  }

  return {
    isRevaled1,
    isRevaled2,
    hideshow1,
    hideshow2,
    requestNewPassword,
    apiError
  };
}