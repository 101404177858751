import React, { useEffect, useState } from "react";
import { useExistingPatientPost } from "./custom-hook/existing-patient-hook";
import { Link } from "react-router-dom";
import "../../assets/css/existing-patient.css";
import { Formik, Form as FormikFrom, ErrorMessage, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import Loader from "../../components/loader";
import uploadImg from "../../assets/img/upload.svg";
import ToastNotification from "../../components/toast-notification";

const ExistingPatientPost = () => {
  const {
    existingPatients,
    selectedPatient,
    handleSelectedPatient,
    selectedPatientSurgey,
    selectedPatientName,
    selectedPatientPhone,
    setSelectedPatientName,
    setSelectedPatientPhone,
    setSelectedPatientSurgery,
    procedures,
    selectedPatientProcedure,
    setSelectedPatientProcedure,
    loading,
    documents,
    setDocuments,
    notes,
    setNotes,
    handleFileSelect,
    handleDelete,
    previewUrls,
    referNewPatientAPI,
    show,
    msg,
    setShow,
    selectedPatientID,
    docUploadError,
    JourneyList,
  } = useExistingPatientPost();

  function getFirstLetters(str) {
    if (str) {
      const firstLetters = str
        .split(" ")
        .map((word) => word.charAt(0))
        .join("");

      return firstLetters.toUpperCase();
    }
  }

  const handleSubmit = async () => {
    const values = {
      user_id: selectedPatientID,
      patient_name: selectedPatientName,
      phone_number: selectedPatientPhone,
      journey_id: selectedPatientProcedure,
      // procedure_id: selectedPatientProcedure,
      documents,
      notes,
      // same_day_surgery: selectedPatientSurgey,
    };
    // console.log("values", values);
    await referNewPatientAPI(values);
  };

  return (
    <section className="section mv__section_refer">
      <div className="container-fluid px-0">
        {loading && <Loader />}
        {show ? (
          <ToastNotification setShow={setShow} show={show} content={msg} />
        ) : null}
        <div
          className="referralschatList mv__referralschatList active"
          // ref={scrollRef}
          style={{ marginLeft: "0px !important" }}
        >
          <div className="chatList-inner">
            <div
              className="charlist-inner-content d-flex"
              style={{ justifyContent: " space-between" }}
            >
              <h2>Patients</h2>
            </div>
          </div>
          <div className="chatUser-list">
            <ul
              className="chatUser-allList mv__chatUser_allList nav nav-pills "
              id="pills-tabs"
              role="tablist"
            >
              {existingPatients?.map((item, index) => {
                const { patient_name } = item;
                return (
                  <li className="nav-item" role="presentation" key={index}>
                    <Link
                      className={index === 0 ? "active" : null}
                      to=""
                      onClick={() => {
                        handleSelectedPatient(patient_name);
                      }}
                      id="pills-userone-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-userone"
                      type="button"
                      role="tab"
                      aria-controls="pills-userone"
                      aria-selected="true"
                    >
                      <div className="chatUser-img mv__chatUser-img">
                        <p className="mv_fisrtleter">
                          {getFirstLetters(patient_name)}
                        </p>
                      </div>
                      <div className="chatUser-name text-capitalize">
                        <h2 className="mb-1">{patient_name}</h2>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="tab-content mv__tabs-content" id="pills-tabContents">
          <div className="Page_content new-patient-page mv__new-patient-page ">
            <div className="row mt-0  pt-0">
              <div className="col-12">
                {selectedPatient && (
                  <form onSubmit={handleSubmit}>
                    <div className="row top-section pb-0">
                      <div className="col-md-3 col-6">
                        <div className="my_patients pb-3 pt-3">
                          <h6 className="mb-0">Patient Post OP</h6>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="my-3 row">
                          <div className="col-md-3 col-12">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Patient Name
                            </label>
                          </div>
                          <div className="col-md-8 col-12">
                            <input
                              type="text"
                              name="patient_name"
                              value={selectedPatientName}
                              className="form-control mv___form_control spacing-row"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              onChange={(e) =>
                                setSelectedPatientName(e.target.value)
                              }
                            />
                            <div
                              className="empty"
                              style={{
                                color: "#C40000",
                                fontSize: "14px",
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="my-3 row">
                          <div className="col-md-3 col-12">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Phone Number
                            </label>
                          </div>
                          <div className="col-md-8 col-12">
                            <PhoneInput
                              onlyCountries={["us"]}
                              disableDropdown
                              countryCodeEditable={false}
                              country={"us"}
                              name="phone_number"
                              value={selectedPatientPhone}
                              onChange={(e) => setSelectedPatientPhone(e)}
                            />

                            <div
                              className="empty"
                              style={{
                                color: "#C40000",
                                fontSize: "14px",
                              }}
                            ></div>
                          </div>
                        </div>
                        {/* <div className="my-3 row">
                          <div className="col-md-3 col-12">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Procedure
                            </label>
                          </div>
                          <div className="col-md-8  col-12">
                            <select
                              id="inputState"
                              className="form-select form-control mv___form_control"
                              name="procedure_id"
                              value={
                                selectedPatientProcedure
                                  ? selectedPatientProcedure
                                  : ""
                              }
                              onChange={(e) =>
                                setSelectedPatientProcedure(e.target.value)
                              }
                            >
                              <option value="">Please select procedure</option>
                              {procedures &&
                                procedures.map((item, index) => {
                                  const { id, procedure } = item;
                                  // console.log("procedureId", procedure);
                                  return (
                                    <React.Fragment key={index}>
                                      <option value={`${id}`}>
                                        {procedure}
                                      </option>
                                    </React.Fragment>
                                  );
                                })}
                            </select>
                          </div>
                        </div> */}
                        <div className="my-3 row">
                          <div className="col-md-3 col-12">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Journey
                            </label>
                          </div>
                          <div className="col-md-8  col-12">
                            <select
                              id="inputState"
                              className="form-select form-control mv___form_control"
                              name="procedure_id"
                              value={
                                selectedPatientProcedure
                                  ? selectedPatientProcedure
                                  : ""
                              }
                              onChange={(e) =>
                                setSelectedPatientProcedure(e.target.value)
                              }
                            >
                              <option value="">Please select Journey</option>
                              {JourneyList &&
                                JourneyList.map((item, index) => {
                                  console.log("item", item);
                                  const { id, title } = item;
                                  // console.log("procedureId", procedure);
                                  return (
                                    <React.Fragment key={index + id}>
                                      <option value={`${id}`}>{title}</option>
                                    </React.Fragment>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div className="my-3 pt-0 mt-3">
                          <>
                            <div className="col px-0">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label document-text"
                                style={{ fontWeight: "unset" }}
                              >
                                Document (Maximum 15)
                              </label>
                            </div>
                            <div
                              className="col-md-12 position px-0"
                              style={{ position: "relative" }}
                            >
                              <label
                                htmlFor="et_pb_contact_brand_file_request_0"
                                className="et_pb_contact_form_label"
                              >
                                <div style={{ textAlign: "center" }}>
                                  <img src={uploadImg} alt="..." />
                                </div>
                              </label>
                              <input
                                type="file"
                                id="et_pb_contact_brand_file_request_0"
                                className="file-upload"
                                name="documents"
                                onChange={(e) => handleFileSelect(e, documents)}
                                multiple
                              />
                              {docUploadError && (
                                <p style={{ color: "red" }}>
                                  *{docUploadError}
                                </p>
                              )}
                              <div
                                className="refferal-img-folder"
                                style={{ display: "flex" }}
                              >
                                {previewUrls &&
                                  previewUrls.map((url, index) => (
                                    <div
                                      key={index}
                                      className="mv__patient_box"
                                    >
                                      <div className="patient-img-holder mv__patient_img_holder">
                                        <img
                                          src={url}
                                          alt="..."
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                          }}
                                        />
                                      </div>
                                      <button
                                        type="button"
                                        className="mv__trash_icon"
                                        onClick={() =>
                                          handleDelete(index, documents)
                                        }
                                      >
                                        <div
                                          className="form-icon"
                                          style={{ color: "red" }}
                                        >
                                          {" "}
                                          <i className="fa-solid fa-trash"></i>
                                        </div>
                                      </button>
                                      <div className="form-icon-new">
                                        {" "}
                                        <i className="fa fa-check"></i>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </>
                        </div>

                        <div className="col-md-12 mt-4 px-0">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label"
                          >
                            <span className="notes">Notes</span>{" "}
                            <span className="optional">(optional)</span>
                          </label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            placeholder="Enter text here..."
                            rows="3"
                            name="notes"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                          ></textarea>
                        </div>

                        {/* <div className="mt-5 form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                            name="same_day_surgery"
                            checked={selectedPatientSurgey == 1 ? true : false}
                            onChange={(e) =>
                              setSelectedPatientSurgery(
                                e.target.checked ? "1" : "0"
                              )
                            }
                          />

                          <label
                            className="form-check-label font-italic "
                            htmlFor="exampleCheck1"
                          >
                            Same (next) day surgery
                          </label>
                        </div> */}
                      </div>
                      <div className="col-md-12 col-6 text-center mt-5 ">
                        <button
                          type="button"
                          className="btn btn-save"
                          onClick={handleSubmit}
                          disabled={
                            !selectedPatientName || !selectedPatientPhone
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ExistingPatientPost;
