import { callAPI } from "../config/api-core";
import { updateDoctorMethod } from "../methods/update-docter-api-method";

const updateDoctorAPI = async(request) => {
    let userToken = localStorage.getItem("token");
    let token = JSON.parse(userToken);
    let header = {
        "content-type": "multipart/form-data",
        Authorization: userToken && `Bearer ${token}`,
    };

    const response = await callAPI(
        updateDoctorMethod,
        false,
        request,
        header,
        false,
        false
    );
    return response;
};

export const updateDoctor = (request) => updateDoctorAPI(request);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    updateDoctor,
};