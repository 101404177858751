import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Modals = (props) => {
  // console.log("props", props);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        {props.docExtension === "pdf" ? (
          <iframe
            src={props.imgUrl}
            alt="..."
            style={{ height: "700px", width: "100%" }}
          />
        ) : props.docExtension == "doc" || props.docExtension == "docx" ? (
          <h1>This file type is not able to view</h1>
        ) : (
          <img
            src={props.imgUrl}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "400px",
              objectFit: " contain",
            }}
          />
        )}

        {/* <iframe src={props.imgUrl} alt="..." /> */}
      </Modal.Body>
    </Modal>
  );
};
export default Modals;
