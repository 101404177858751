import { callAPI } from "../config/api-core";
import {newPasswordAPIMethods} from "../methods/new-password-api-method"

const newPasswordAPI = async (request) => {
  const response = await callAPI(
    newPasswordAPIMethods,
    false,
    request,
    {},
    false,
    false
  );
  return response;
};

export const newPasswordFetch = (request) => newPasswordAPI(request);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    newPasswordFetch,
};
