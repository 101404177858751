import { callAPI } from "../config/api-core";
import {refers} from "../methods/refer-api-method";
import {procedureAPIMethods} from "../methods/procedure-api-method";

const referAPI = async (request) => {
    let userToken = localStorage.getItem("token");
    let token = JSON.parse(userToken)
    let header = {
      'content-type': 'multipart/form-data',
      Authorization: userToken && `Bearer ${token}`,
    };
  
  const response = await callAPI(
    refers,
    false,
    request,
    header,
    false,
    false
  );
  return response;
};


export const referNewPatient = (request) => referAPI(request);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  referNewPatient,

};
