import { callAPI } from "../config/api-core";
import { existingPatientJourneyListAPIMethods } from "../methods/existing-patient-journey-list-api-method";

const existingPatientJourneyListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let token = JSON.parse(userToken);
  let header = {
    // "content-type": "multipart/form-data",
    Authorization: userToken && `Bearer ${token}`,
  };
  const response = await callAPI(
    existingPatientJourneyListAPIMethods,
    true,
    {},
    header,
    false,
    false
  );
  return response;
};

export const existingPatientJourneyList = (request) => existingPatientJourneyListAPI(request);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    existingPatientJourneyList,
};
