import { useMemo, useState } from "react";
import RecordNotFound from "./recorderror";
import { usePubNub } from "pubnub-react";
import { useEffect } from "react";
import { memo } from "react";

const Activity = ({
  showDocTab,
  selectedPatient,
  messages,
  activity,
  channels,
}) => {
  // console.log("Activity component rendered");
  const [pubnubMsg, setPubnubMsg] = useState([]);

  const value = useMemo(() => {
    let arr = [];
    if (messages) {
      for (const element of messages) {
        arr.unshift(element);
      }
      setPubnubMsg(arr);
    }
  }, [messages]);

  return (
    <div
      className={showDocTab ? "tab-pane fade active show" : "tab-pane fade"}
      id="pills-home"
      role="tabpanel"
      aria-labelledby="pills-home-tab"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 col-sm-12 ">
              <div className="table_wrapper table-responsive">
                <table className="table My_table mv__activity_list mt-0">
                  <tbody className="tbody-border" id={`${channels[0]}`}>
                    {pubnubMsg.length > 0
                      ? pubnubMsg.map((item, index) => {
                          if (item.channel === channels[0]) {
                            return (
                              <tr className="" key={index}>
                                <td>
                                  <span className=" Dr_Josaph ">
                                    {item.message.description}
                                  </span>
                                </td>
                                <td className="Yesterday_text">
                                  {item.message.created_at}
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })
                      : null}

                    {/* {messages.length > 0 ? (
                      messages.map((msg, index) => {
                        return (
                          <tr className="" key={index}>
                            <td className="patiend-photo-table-td">
                              <div className="report_img">
                                <img
                                  src="https://images.examples.com/wp-content/uploads/2017/04/Medical-Condition-Report1.jpg"
                                  alt=""
                                />
                              </div>
                            </td>
                            <td>
                              <span className=" Dr_Josaph ">
                                {msg.message?.description}
                              </span>
                              sent a{" "}
                              <span className="Dr_Josaph">
                                Catract Procedure
                              </span>{" "}
                              Document.
                            </td>
                            <td className="Yesterday_text">
                              {msg.message?.created_at}
                            </td>
                          </tr>
                        );
                      })
                    ) : */}
                    {
                      activity.length > 0 ? (
                        activity.map((item, index) => {
                          return (
                            <tr className="" key={index}>
                              {/* <td className="patiend-photo-table-td">
                              <div className="report_img">
                                <img
                                  src="https://images.examples.com/wp-content/uploads/2017/04/Medical-Condition-Report1.jpg"
                                  alt=""
                                />
                              </div>
                            </td> */}
                              <td>
                                <span className=" Dr_Josaph ">{item.text}</span>
                              </td>
                              <td className="Yesterday_text fsdff">
                                {item.created_at_modified}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <RecordNotFound heading="no activity found" />
                      )
                      // <RecordNotFound heading="no activity found" />
                    }
                  </tbody>
                </table>
              </div>
              {/* <RecordNotFound heading="no activity found" /> */}
              {/* <div className="table_wrapper table-responsive">
                <table className="table My_table">
                  <tbody>
                    <tr className="">
                      <td className="patiend-photo-table-td">
                        <div className="report_img">
                          <img
                            src="https://images.examples.com/wp-content/uploads/2017/04/Medical-Condition-Report1.jpg"
                            alt=""
                          />
                        </div>
                      </td>
                      <td>
                        <span className=" Dr_Josaph ">Dr. Josaph </span>
                        sent a <span className="Dr_Josaph">
                          Catract Procedure
                        </span>{" "}
                        Document.
                      </td>
                      <td className="Yesterday_text">Yesterday</td>
                    </tr>
                    <tr className="">
                      <td>
                        <div className="report_img">
                          <img
                            src="https://images.examples.com/wp-content/uploads/2017/04/Medical-Condition-Report1.jpg"
                            alt=""
                          />
                        </div>
                      </td>
                      <td>
                        <span className=" Dr_Josaph ">Dr. Josaph </span>
                        sent a <span className="Dr_Josaph">
                          Catract Procedure
                        </span>{" "}
                        Document.
                      </td>
                      <td className="Yesterday_text">Yesterday</td>
                    </tr>
                    <tr className="">
                      <td>
                        <div className="report_img">
                          <img
                            src="https://images.examples.com/wp-content/uploads/2017/04/Medical-Condition-Report1.jpg"
                            alt=""
                          />
                        </div>
                      </td>
                      <td>
                        <span className=" Dr_Josaph ">Dr. Josaph </span>
                        sent a <span className="Dr_Josaph">
                          Catract Procedure
                        </span>{" "}
                        Document.
                      </td>
                      <td className="Yesterday_text">Yesterday</td>
                    </tr>
                    <tr className="">
                      <td>
                        <div className="report_img">
                          <img
                            src="https://images.examples.com/wp-content/uploads/2017/04/Medical-Condition-Report1.jpg"
                            alt=""
                          />
                        </div>
                      </td>
                      <td>
                        <span className=" Dr_Josaph ">Dr. Josaph </span>
                        sent a <span className="Dr_Josaph">
                          Catract Procedure
                        </span>{" "}
                        Document.
                      </td>
                      <td className="Yesterday_text">Yesterday</td>
                    </tr>
                    <tr className="">
                      <td>
                        <div className="report_img">
                          <img
                            src="https://images.examples.com/wp-content/uploads/2017/04/Medical-Condition-Report1.jpg"
                            alt=""
                          />
                        </div>
                      </td>
                      <td>
                        <span className=" Dr_Josaph ">Dr. Josaph </span>
                        sent a <span className="Dr_Josaph">
                          Catract Procedure
                        </span>{" "}
                        Document.
                      </td>
                      <td className="Yesterday_text">Yesterday</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Activity);
