import { callAPI } from "../config/api-core";
import {logins} from "../methods/login-api-method"

const loginAPI = async (request) => {
  // console.log("hiii")
  const response = await callAPI(
    logins,
    false,
    request,
    {},
    false,
    false
  );
  return response;
};

export const auth = (request) => loginAPI(request);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  auth,
};
