import img1 from "../../assets/img/manson-logo.png";
import img2 from "../../assets/img/main-img.png";
import { Link, useLocation } from "react-router-dom";
import { Formik, Form as FormikFrom, ErrorMessage } from "formik";
import { validation } from "./validation";
import { useChangePwd } from "./custom-hook/change-pwd-hook";
import { useEffect, useRef, useState } from "react";

const ChangePassword = () => {
  const { isRevaled1, isRevaled2, hideshow1, hideshow2, requestNewPassword, apiError } = useChangePwd();
  const [otpError, setOtpError] = useState("");
  const [optPass, setOptPass] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: ""
  });
  const { state } = useLocation();

  const inputFocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      // console.log("prev");
      const prev = elmnt.target.tabIndex;
      // console.log(prev);
      if (prev > 1) {
        elmnt.target.form.elements[prev].focus()
      }
    }
    else {
      // console.log("next");
      const next = elmnt.target.tabIndex;
      // console.log(next);
      if (next < 4) {
        elmnt.target.form.elements[next + 2].focus()
      }
    }
  }

  function handleInputChange(value1, event) {
    setOtpError("");
    setOptPass(prevState => ({
      ...prevState,
      [value1]: event.target.value
    }));
  }

  const handleValidateOtp = (values) => {
    const isOptPassComplete = Object.values(optPass).every(val => val);

    if (isOptPassComplete) {
      const otp = Object.values(optPass);
      const newOtp = otp.join("");

      const newValues = {
        email: state?.data,
        otp: newOtp,
        password: values.confirmPwd
      }
      requestNewPassword(newValues);
    } else {
      setOtpError("Please enter 4-digits OTP");
    }
  }

  return (
    <section className="Login_section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="row main_row">
              <div className="col-md-6">
                <div className="left_side">
                  <div className="logo_card mb-4 ms-0">
                    <img src={img1} alt="..." />
                  </div>
                  <div className="left_text">
                    <h2>Change Password</h2>
                    <p>
                      Please enter your new password to change password for your
                      account.
                    </p>
                  </div>
                  <Formik
                    initialValues={{
                      newPwd: "",
                      confirmPwd: "",
                    }}
                    validationSchema={validation}
                    onSubmit={(values) => {
                      handleValidateOtp(values)
                    }}
                  >
                    {({ handleChange, handleBlur }) => (
                      <FormikFrom>
                        <div>
                          {apiError && (
                            <span
                              className="error"
                              style={{ color: "#C40000", fontSize: "14px" }}
                            >
                              {apiError}
                            </span>
                          )}
                        </div>
                        <div className="mb-3 eyeicon">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label password_text"
                          >
                            New password
                          </label>
                          <input
                            type={isRevaled1 ? "text" : "password"}
                            className="form-control"
                            id="exampleInputPassword1"
                            name="newPwd"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {isRevaled1 ? (
                            <i
                              className="fa fa-eye-slash mv__password_eye"
                              // style={{ fontSize: "17px", color: "#33B0CE" }}
                              onClick={() => hideshow1()}
                            />
                          ) : (
                            <i
                              className="fa fa-eye mv__password_eye"
                              // style={{ fontSize: "17px", color: "#33B0CE" }}
                              onClick={() => hideshow1()}
                            />
                          )}
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="newPwd" />
                          </div>
                        </div>
                        <div className="mb-3 eyeicon">
                          <label
                            htmlFor="exampleInputPassword1"
                            className="form-label password_text"
                          >
                            Confirm password
                          </label>
                          <input
                            type={isRevaled2 ? "text" : "password"}
                            className="form-control"
                            id="exampleInputPassword1"
                            name="confirmPwd"
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {isRevaled2 ? (
                            <i
                              className="fa fa-eye-slash"
                              style={{ fontSize: "17px", color: "#33B0CE" }}
                              onClick={() => hideshow2()}
                            />
                          ) : (
                            <i
                              className="fa fa-eye"
                              style={{ fontSize: "17px", color: "#33B0CE" }}
                              onClick={() => hideshow2()}
                            />
                          )}
                          <div
                            className="empty"
                            style={{ color: "#C40000", fontSize: "14px" }}
                          >
                            <ErrorMessage name="confirmPwd" />
                          </div>
                        </div>
                        <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                          <input
                            name="otp1"
                            type="text"
                            autoComplete="off"
                            className="m-2 text-center form-control rounded otp_field"
                            value={optPass.otp1}
                            onChange={(e) => handleInputChange("otp1", e)}
                            tabIndex="1"
                            maxLength="1"
                            onKeyUp={e => inputFocus(e)}
                          />
                          <input
                            name="otp2"
                            type="text"
                            autoComplete="off"
                            className="m-2 text-center form-control rounded otp_field"
                            value={optPass.otp2}
                            onChange={(e) => handleInputChange("otp2", e)}
                            tabIndex="2"
                            maxLength="1"
                            onKeyUp={e => inputFocus(e)}

                          />
                          <input
                            name="otp3"
                            type="text"
                            autoComplete="off"
                            className="m-2 text-center form-control rounded otp_field"
                            value={optPass.otp3}
                            onChange={(e) => handleInputChange("otp3", e)}
                            tabIndex="3"
                            maxLength="1"
                            onKeyUp={e => inputFocus(e)}

                          />
                          <input
                            name="otp4"
                            type="text"
                            autoComplete="off"
                            className="m-2 text-center form-control rounded otp_field"
                            value={optPass.otp4}
                            onChange={(e) => handleInputChange("otp4", e)}
                            tabIndex="4"
                            maxLength="1"
                            onKeyUp={e => inputFocus(e)}
                          />
                        </div>
                        <div>
                          {otpError && (
                            <span
                              className="error"
                              style={{ color: "#C40000", fontSize: "14px" }}
                            >
                              {otpError}
                            </span>
                          )}
                        </div>
                        <button
                          type="submit"
                          className="btn btn-primary Login_button"
                        >
                          Continue
                        </button>
                        <div className="Forgot_password">
                          <Link to="/forgot-pwd">Back</Link>
                        </div>
                      </FormikFrom>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="col-md-6 mt-5">
                <div className="right_side">
                  <h2>Refer New Patients & Patient Journey </h2>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  <div className="main_img">
                    <img src={img2} alt="..." />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ChangePassword;
