import "../../assets/css/refernewpatient.css";
import { useRefer } from "./custom-hook/refer-hook";
import { Formik, Form as FormikFrom, ErrorMessage, Field } from "formik";
import { validation } from "./validation";
import React, { useEffect, useState } from "react";
import ToastNotification from "../../components/toast-notification";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Loader from "../../components/loader";
import uploadImg from "../../assets/img/upload.svg";

const Newpatient = () => {
  const {
    referNewPatientAPI,
    show,
    msg,
    setShow,
    check,
    setCheck,
    previewUrls,
    handleDelete,
    handleFileSelect,
    procedures,
    firstProcedure,
    loading,
    docUploadError,
  } = useRefer();

  // useEffect(() => {
  //   console.log("ppppp", procedures);
  // }, [procedures]);

  return (
    <>
      <section className="section mv_main__sec">
        <div className="container-fluid px-0">
          {loading && <Loader />}
          <div className="Page_content new-patient-page mv__new-patient-page ">
            <div className="row mt-0  pt-0">
              <div>
                {show ? (
                  <ToastNotification
                    setShow={setShow}
                    show={show}
                    content={msg}
                  />
                ) : null}
              </div>
              <div className="col-12">
                <Formik
                  initialValues={{
                    patient_name: "",
                    phone_number: "",
                    procedure_id: "",
                    documents: [],
                    // documents: null,
                    notes: "",
                    same_day_surgery: "0",
                  }}
                  validationSchema={validation}
                  onSubmit={async (values, { resetForm }) => {
                    // console.log(values);

                    await referNewPatientAPI(values);
                    resetForm({ values: "" });
                    setCheck(check ? !check : check);
                  }}
                >
                  {({ handleChange, handleBlur, values, setFieldValue }) => (
                    <FormikFrom>
                      <div className="row top-section pb-0">
                        <div className="col-md-3 col-6">
                          <div className="my_patients pb-3 pt-3">
                            <h6 className="mb-0">New Patient</h6>
                          </div>
                        </div>
                        {/* <div className="col-md-8 col-6 text-end ">
                          <button type="submit" className="btn btn-save">
                            Save
                          </button>
                        </div> */}
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="my-3 row">
                            <div className="col-md-3 col-12">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Patient Name
                              </label>
                            </div>
                            <div className="col-md-8 col-12">
                              <input
                                type="text"
                                name="patient_name"
                                value={values.patient_name}
                                className="form-control mv___form_control spacing-row"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div
                                className="empty"
                                style={{ color: "#C40000", fontSize: "14px" }}
                              >
                                <ErrorMessage name="patient_name" />
                              </div>
                            </div>
                          </div>
                          <div className="my-3 row">
                            <div className="col-md-3 col-12">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Phone Number
                              </label>
                            </div>
                            <div className="col-md-8 col-12">
                              <PhoneInput
                                onlyCountries={["us"]}
                                disableDropdown
                                countryCodeEditable={false}
                                country={"us"}
                                name="phone_number"
                                value={values.phone_number}
                                //  onChange={(e)=>handlePhone(e, setFieldValue,)}
                                onChange={(e) =>
                                  setFieldValue("phone_number", e)
                                }
                              />

                              <div
                                className="empty"
                                style={{ color: "#C40000", fontSize: "14px" }}
                              >
                                <ErrorMessage name="phone_number" />
                              </div>
                            </div>
                          </div>
                          <div className="my-3 row">
                            <div className="col-md-3 col-12">
                              <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                              >
                                Procedure
                              </label>
                            </div>
                            <div className="col-md-8  col-12">
                              <select
                                id="inputState"
                                className="form-select form-control mv___form_control"
                                name="procedure_id"
                                value={values.procedure_id}
                                onChange={handleChange}
                              >
                                <option>Please select procedure</option>
                                {procedures &&
                                  procedures.map((item, index) => {
                                    const { id, procedure } = item;
                                    // console.log("procedureId", procedure);
                                    return (
                                      <React.Fragment key={index}>
                                        <option value={`${id}`}>
                                          {procedure}
                                        </option>
                                      </React.Fragment>
                                    );
                                  })}
                              </select>
                              {/* <div
                                className="empty"
                                style={{ color: "#C40000", fontSize: "14px" }}
                              >
                                <ErrorMessage name="procedure" />
                              </div> */}
                            </div>
                          </div>
                          <div className="my-3 pt-0 mt-3">
                            {/* {isDesktop ? ( */}
                            <>
                              <div className="col px-0">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label document-text"
                                  style={{ fontWeight: "unset" }}
                                >
                                  Document (Maximum 15)
                                </label>
                              </div>
                              <div
                                className="col-md-12 position px-0"
                                style={{ position: "relative" }}
                              >
                                <label
                                  htmlFor="et_pb_contact_brand_file_request_0"
                                  className="et_pb_contact_form_label"
                                >
                                  <div style={{ textAlign: "center" }}>
                                    <img src={uploadImg} alt="..." />
                                  </div>
                                </label>
                                <input
                                  type="file"
                                  id="et_pb_contact_brand_file_request_0"
                                  className="file-upload"
                                  name="documents"
                                  onChange={(e) =>
                                    handleFileSelect(
                                      e,
                                      setFieldValue,
                                      values.documents
                                    )
                                  }
                                  // onChange={(e) => {
                                  //   values.documents = e.target.files;
                                  // }}
                                  // onChange={e=>{ handleChange(e); setFieldValue("documents",e.currentTarget.files)}}
                                  multiple
                                />
                                {docUploadError && (
                                  <p style={{ color: "red" }}>
                                    *{docUploadError}
                                  </p>
                                )}

                                <div
                                  className="refferal-img-folder"
                                  style={{ display: "flex" }}
                                >
                                  {previewUrls &&
                                    previewUrls.map((url, index) => (
                                      <div
                                        key={index}
                                        className="mv__patient_box"
                                      >
                                        <div
                                          className="patient-img-holder mv__patient_img_holder"
                                          // style={{
                                          //   width: "90px",
                                          //   height: "90px",
                                          // }}
                                        >
                                          <img
                                            src={url}
                                            alt="..."
                                            style={{
                                              height: "100%",
                                              width: "100%",
                                            }}
                                          />
                                        </div>
                                        <button
                                          type="button"
                                          className="mv__trash_icon"
                                          onClick={() =>
                                            handleDelete(
                                              index,
                                              setFieldValue,
                                              values.documents
                                            )
                                          }
                                        >
                                          <div
                                            className="form-icon"
                                            style={{ color: "red" }}
                                          >
                                            {" "}
                                            <i className="fa-solid fa-trash"></i>
                                          </div>
                                        </button>
                                        <div
                                          className="form-icon-new"
                                          // style={{ color: "green", position: "absolute", bottom: 0, right: 0 }}
                                        >
                                          {" "}
                                          <i className="fa fa-check"></i>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </>
                          </div>

                          <div className="col-md-12 mt-4 px-0">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              className="form-label"
                            >
                              <span className="notes">Notes</span>{" "}
                              <span className="optional">(optional)</span>
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              placeholder="Enter text here..."
                              rows="3"
                              name="notes"
                              value={values.notes}
                              onChange={handleChange}
                            ></textarea>
                          </div>
                          <div className="mt-5 form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="exampleCheck1"
                              name="same_day_surgery"
                              // value={check}
                              checked={check}
                              //  value="1"
                              // onChange={handleChange}
                              onChange={(e) => {
                                values.same_day_surgery = e.target.checked
                                  ? "1"
                                  : "0";
                                setCheck(e.target.checked);
                              }}
                            />

                            <label
                              className="form-check-label font-italic "
                              htmlFor="exampleCheck1"
                            >
                              Same (next) day surgery
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12 col-6 text-center mt-5 ">
                          <button type="submit" className="btn btn-save">
                            Submit
                          </button>
                        </div>
                      </div>
                    </FormikFrom>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Newpatient;
