import React, { useContext, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { UserContext } from "../App";
import api from "../services/api/update-doctor-api";
import { useDashboard } from "../pages/Dashboard/custom-hook/dashboard-hook";
// import Profileicon from "../assets/img/profile-pic.png";
import "../assets/css/profile-modal.css";
import DoctorImg from "../assets/img/DoctorImg.png";

const ProfileModal = ({ show, setShow }) => {
  const { profile } = useContext(UserContext);
  console.log(profile);
  const { dashboardAPI } = useDashboard();
  const [name, setName] = useState(profile?.profileName);
  const [email, setEmail] = useState(profile?.profileEmail);
  const [phone, setPhone] = useState(profile?.profilePhone);
  const [image, setImage] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const handleNameChange = (e) => {
    const value = e.target.value;
    if (value.length <= 24) {
      setName(value);
      setError("");
    } else {
      setError("Name should not exceed 24 characters");
    }
    // setName(e.target.value)
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value) {
      setErrorEmail("Email cannot be empty");
    } else if (!emailRegex.test(value)) {
      setErrorEmail("Invalid email format");
    } else {
      setEmail(value);
      setErrorEmail("");
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) {
      setPhone(value);
      setErrorPhone("");
    } else {
      setErrorPhone("Please enter valid phone");
    }
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
     console.log(`Updating profile: Name - ${name}, Image - ${image}`);
    const request = {
      name: name,
      image: image,
      phone_number: phone,
      email: email,
    };
    console.log(request);
    setLoading(true);
    try {
      const response = await api.updateDoctor(request);
      console.log("Response", response);
      if (response && response.data.status === 1) {
        // console.log("sucess", response.data.message);
        dashboardAPI();
        handleClose();
        setLoading(false);
      } else {
        console.log("error");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    handleClose();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className=""
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title>Update Profile Details</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="p-0">
          <div className="profile-modal-card">
            <Modal.Header className="profile-modal-head" closeButton >
              Update Profile
            </Modal.Header>
            <div className="profile-modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="avatar-upload">
                    <div className="avatar-edit">
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleImageUpload}
                      />
                      <label htmlFor="imageUpload"></label>
                    </div>
                    <div className="avatar-preview">
                      {previewImage ? (
                        <div
                          id="imagePreview"
                          style={{ backgroundImage: `url(${previewImage})` }}
                        ></div>
                      ) : profile.profileImg ? (
                            <img id="imagePreview" src={profile?.profileImg} alt="" />
                        
                      ) : (
                        <img id="imagePreview" src={DoctorImg} alt="" />
                       
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center mb-3 ">
                <div className="col-12 col-sm-4 col-md-3">
                  <label className="labelInput">Name</label>
                </div>
                <div className="col-12 col-sm-8 col-md-9">
                  <input
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={handleNameChange}
                    className="nameInput"
                  />
                  {error && (
                    <div className="error">
                      {error}
                    </div>
                  )}
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-12 col-sm-4 col-md-3">
                  <label className="labelInput">Email</label>
                </div>
                <div className="col-12 col-sm-8 col-md-9">
                  <input
                    type="text"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    className="nameInput"
                  />
                  {errorEmail && (
                    <div className="error">
                      {errorEmail}
                    </div>
                  )}
                </div>
              </div>
              <div className="row align-items-center mb-3">
                <div className="col-12 col-sm-4 col-md-3">
                  <label className="labelInput">Phone</label>
                </div>
                <div className="col-12 col-sm-8 col-md-9">
                  <input
                    type="text"
                    placeholder="Enter your Phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    className="nameInput"
                  />
                  {errorPhone && (
                    <div className="error">
                      {errorPhone}
                    </div>
                  )}
                </div>
              </div>
              <div className="row border-top-1">
                <div className="col-12">
                  <Button
                    variant="primary"
                    type="button"
                    onClick={handleProfileSubmit}
                    disabled={!name}
                    className="mv_Save"
                  >
                    {loading ? (
                      <>
                        Save Changes <Spinner animation="border" size="sm" />
                      </>
                    ) : (
                      "Save Changes"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="imG-previewMv">
            <div className="avatar-upload">
              <div className="avatar-edit">
                <input
                  type="file"
                  id="imageUpload"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleImageUpload}
                />
                <label htmlFor="imageUpload"></label>
              </div>
              <div className="avatar-preview">
                {previewImage ? (
                  <div
                    id="imagePreview"
                    style={{ backgroundImage: `url(${previewImage})` }}
                  ></div>
                ) : profile.profileImg ? (
                  <div
                    id="imagePreview"
                    style={{ backgroundImage: `url(${profile?.profileImg})` }}
                  ></div>
                ) : (
                  <div
                    id="imagePreview"
                    style={{ backgroundImage: `url(${DoctorImg})` }}
                  ></div>
                )}
              </div>
            </div>
            <div className="profile-form">
              <div className="row">
                <div className="col-12">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={handleNameChange}
                    className="nameInput"
                  />

                  {error && (
                    <div className="error" style={{ color: "red" }}>
                      {error}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Email</label>
                  <input
                    type="text"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    className="nameInput"
                  />
                  {setErrorEmail && (
                    <div className="error" style={{ color: "red" }}>
                      {setErrorEmail}
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Phone</label>
                  <input
                    type="text"
                    placeholder="Enter your email"
                    value={phone}
                    onChange={handlePhoneChange}
                    className="nameInput"
                  />
                  {setErrorPhone && (
                    <div className="error" style={{ color: "red" }}>
                      {setErrorPhone}
                    </div>
                  )}
                </div>
              </div>
              <Button
                variant="primary"
                type="button"
                onClick={handleProfileSubmit}
                disabled={!name}
                className="mv_Save"
              >
                {loading ? (
                  <>
                    Save Changes <Spinner animation="border" size="sm" />
                  </>
                ) : (
                  "Save Changes"
                )}
              </Button>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileModal;
