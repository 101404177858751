import { callAPI } from "../config/api-core";
import { notificationsAPIMethods } from "../methods/notifications-api-method";

const notificationsListAPI = async () => {
  let userToken = localStorage.getItem("token");
  let token = JSON.parse(userToken);
  let header = {
    // "content-type": "multipart/form-data",
    Authorization: userToken && `Bearer ${token}`,
  };
  const response = await callAPI(
    notificationsAPIMethods,
    false,
    {},
    header,
    false,
    false
  );
  return response;
};

export const notificationsList = () => notificationsListAPI();
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  notificationsList,
};
