import { callAPI } from "../config/api-core";
import {referPatient} from "../methods/refer-patients-list-api-method"

const referPatientListAPI = async (request) => {
  let userToken = localStorage.getItem("token");
  let token = JSON.parse(userToken)
  let header = {
    // 'content-type': 'multipart/form-data',
    Authorization: userToken && `Bearer ${token}`,
  };
  const response = await callAPI(
    referPatient,
    false,
    request,
    header,
    false,
    false
  );
  return response;
};

export const referPatientsList = (request) => referPatientListAPI(request);
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  referPatientsList,
};
