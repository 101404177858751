import "../../assets/css/dashboard.css";
import "../../assets/css/my-patient.css";
import totalpatient from "../../assets/img/total-patients-icon.svg";
import totaldocument from "../../assets/img/total-documents-icon.svg";
import { useDashboard } from "./custom-hook/dashboard-hook";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import pdfImg from "../../assets/img/pdf-img.png";
import docImg from "../../assets/img/doc-logo.png";
import { useState } from "react";
import Modals from "../../components/modal";

const Dashboard = () => {
  const {
    documents,
    patientsList,
    totalPatients,
    totalDocuments,
    recentDocuments,
    recentPatientsList,
    doctorName,
    loading,
    profile,
  } = useDashboard();
  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [extension, setExtension] = useState("");

  function getFirstLetters(str) {
    if (str) {
      const words = str.split(" ");
      const convertedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
      const convertedName = convertedWords.join(" ");

      return convertedName;
    }
  }

  const handleModal = (e, img, document_extension) => {
    e.preventDefault();
    setImgUrl(img);
    setExtension(document_extension);
    setModalShow(true);
  };

  const handleNavSearch = (name) => {
    // console.log("hii navbar", name);
    navigate(`/mypatient/${name}`);
    // setShowPatient(false);
    // setSearchTerm("");
  };

  return (
    <>
      <section className="section home-page-section">
        <div className="container-fluid px-0">
          {loading && <Loader />}
          {modalShow ? (
            <Modals
              show={modalShow}
              imgUrl={imgUrl}
              onHide={() => setModalShow(false)}
              docExtension={extension}
            />
          ) : null}

          <div className="Page_content pt-0">
            <div className="row pt-0 page-top-insights mb-4">
              <div className="col-12 my-4">
                <div className="main_heading">
                  <h4 className="mb-0">
                    Welcome back, {getFirstLetters(profile?.profileName)}!
                  </h4>
                </div>
              </div>
              <div className="col-md-3 insight-box mb-3">
                <Link to="/newpatient" style={{ textDecoration: "none" }}>
                  <div className="Refer_new_patient">
                    <div className="Total_Patients_text">
                      <p>Refer New Patient</p>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        {/* <h3 className="text_232">{totalPatients}</h3> */}
                      </div>
                      <div className="col-6 total_patients_icon">
                        {/* <img src={totalpatient} alt="" /> */}
                        <i
                          className="fa fa-user-plus"
                          style={{ fontSize: "40px", color: "#ffffff" }}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-3 insight-box mb-3">
                <Link
                  to="/existing-patient-post"
                  style={{ textDecoration: "none" }}
                >
                  <div className="Existing_Patients">
                    <div className="Total_Patients_text">
                      <p>Existing Patient Post OP</p>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        {/* <h3 className="text_342">{totalDocuments}</h3> */}
                      </div>
                      <div className="col-6 total_patients_icon">
                        {/* <img src={totaldocument} alt="" /> */}
                        <i
                          className=" fa fa-file"
                          style={{ fontSize: "40px", color: "#ffffff" }}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-md-3 insight-box mb-3">
                <div className="Total_document">
                  <div className="Total_Patients_text">
                    <p>Total Patients</p>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <h3 className="text_232">{totalPatients}</h3>
                    </div>
                    <div className="col-6 total_patients_icon">
                      <i
                        className="fa fa-users"
                        style={{ fontSize: "40px", color: "#C87F7F" }}
                      />
                      {/* <img src={totalpatient} alt="" /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 insight-box mb-3">
                <div className="Total_document">
                  <div className="Total_Patients_text">
                    <p>Total Documents</p>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <h3 className="text_232">{totalDocuments}</h3>
                    </div>
                    <div className="col-6 total_patients_icon">
                      <i
                        className=" fa fa-file-text"
                        style={{ fontSize: "40px", color: "#C87F7F" }}
                      />
                      {/* <img src={totaldocument} alt="" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-12 col-lg-6 mb-4">
                    <div className="table-box">
                      <div className="row">
                        <div className="col-8 sm-2">
                          <h4 className="recent-Patients-text mv__recent-Patients-text">
                            Recent Patients
                          </h4>
                        </div>
                        {patientsList.length > 0 ? (
                          <>
                            <div className="col-4 sm-2">
                              <div className="view-all-btn">
                                <Link to="/mypatient">View All</Link>
                              </div>
                            </div>
                            {/* <div className="search-icon">
                          <InputField
                            type="search"
                            inputClassName="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Search patients..."
                          />
                          <input
                            type="search"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Search patients..."
                          />
                          <img
                            className="magnifier-icon"
                            src={searchimg}
                            alt=""
                          />
                        </div> */}
                            <div className="Recent_Referrals_card ">
                              <div className="table_wrapper table-responsive">
                                <table className="table My-table dashboard_table mt-0">
                                  <thead className="th-border mv_thead">
                                    <tr className="table-heading">
                                      <th scope="col">Patient Name</th>
                                      <th scope="col">Procedure</th>
                                      {/* <th scope="col">Referred By</th> */}
                                      <th scope="col">Phone Number</th>
                                      <th scope="col">Journey </th>
                                    </tr>
                                  </thead>
                                  <tbody className="table-body mv_tbody">
                                    {patientsList &&
                                      patientsList.map((item, index) => {
                                        const {
                                          patient_name,
                                          phone_number,
                                          patient_procedure,
                                          status,
                                        } = item;
                                        // console.log("item", item)
                                        return (
                                          <tr
                                            className="table_data text-capitalize"
                                            key={index}
                                            onClick={() =>
                                              handleNavSearch(patient_name)
                                            }
                                          >
                                            {/* <td className="table_inner"> */}
                                            <td>{patient_name}</td>
                                            <td>
                                              {patient_procedure?.procedure
                                                ? patient_procedure.procedure
                                                : "N/A"}
                                            </td>
                                            {/* <td></td> */}
                                            <td>+{phone_number}</td>
                                            <td>{status}</td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        ) : (
                          <h5 className="text-center mt-5 mb-5">
                            No Patients Found...
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12  col-lg-6 mt-md-0 mt-lg-0 mb-4">
                    <div className="table-box">
                      <div className="row">
                        <div className="col-8 sm-2">
                          <h4 className="recent-Patients-text mv__recent-Patients-text">
                            Recent Documents{" "}
                          </h4>
                        </div>
                        {documents.length > 0 ? (
                          <>
                            <div className="col-4 sm-2">
                              <div className="view-all-btn">
                                <Link to="/documents">View All</Link>
                              </div>
                            </div>
                            {/* <div className="search-icon">
                          <InputField
                            type="search"
                            inputClassName="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Search patients..."
                          />
                          <img
                            className="magnifier-icon"
                            src={searchimg}
                            alt=""
                          />
                        </div> */}

                            <div className="row">
                              <div className="col-md-4"></div>
                            </div>

                            <div className="Recent_Referrals_card">
                              <div className="report-card row">
                                {documents &&
                                  documents.map((item, index) => {
                                    const {
                                      document_path,
                                      updated_at,
                                      patient,
                                      document_extension,
                                    } = item;
                                    // console.log("itttt", item);
                                    return (
                                      <div
                                        className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3"
                                        key={index}
                                      >
                                        <div
                                          className="report_image "
                                          onClick={(e) =>
                                            handleModal(
                                              e,
                                              document_path,
                                              document_extension
                                            )
                                          }
                                        >
                                          <div
                                            className="report_img_holder"
                                            style={{
                                              width: "100%",
                                              height: "130px",
                                            }}
                                          >
                                            {item.document_extension ===
                                              "doc" ||
                                            item.document_extension ===
                                              "docx" ? (
                                              <img
                                                src={docImg}
                                                alt=""
                                                width="100%"
                                                height="100%"
                                              />
                                            ) : (
                                              // <p className="ps-2 ">
                                              //   {item.document_extension}
                                              // </p>
                                              <img
                                                src={
                                                  item.document_extension ===
                                                  "pdf"
                                                    ? pdfImg
                                                    : item.document_path
                                                }
                                                alt=""
                                                width="100%"
                                                height="100%"
                                              />
                                            )}
                                            {/* <img
                                              src={
                                                document_extension == "pdf"
                                                  ? pdfImg
                                                  : document_path
                                              }
                                              alt=""
                                              width="100%"
                                              height="100%"
                                            /> */}
                                          </div>
                                          <div className="report-inner">
                                            <p className="last-update">
                                              Last Updated: {updated_at}
                                            </p>
                                            <p className="aidan-text text-capitalize">
                                              {patient?.patient_name}
                                            </p>
                                            <p className="procedure-p">
                                              <span className="Procedure-text">
                                                Procedure:
                                              </span>{" "}
                                              <span className="Cataract-text">
                                                {" "}
                                                {item.procedure
                                                  ? item.procedure.procedure
                                                  : "N/A"}
                                              </span>
                                            </p>
                                            <p>
                                              <span className="Procedure-text">
                                                Journey :
                                              </span>{" "}
                                              <span className="Cataract-text">
                                                {" "}
                                                {item.patient_journey
                                                  ? item.patient_journey
                                                      .journey_data?.title
                                                  : "N/A"}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}

                                {/* <div className="report_image">
                              <img src={reportimg} alt="" />
                              <div className="report-inner">
                                <p className="last-update">
                                  Last Update: 30 Oct, 2022
                                </p>
                                <p className="aidan-text">Aidan Nathan</p>
                                <p>
                                  <span className="Procedure-text">
                                    Procedure:
                                  </span>{" "}
                                  <span className="Cataract-text">
                                    {" "}
                                    Cataract
                                  </span>
                                </p>
                                <p>
                                  <span className="Procedure-text">
                                    Status:
                                  </span>{" "}
                                  <span className="Cataract-text">
                                    {" "}
                                    O.D. Pre-OP
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="report_image">
                              <img src={reportimg} alt="" />
                              <div className="report-inner">
                                <p className="last-update">
                                  Last Update: 30 Oct, 2022
                                </p>
                                <p className="aidan-text">Aidan Nathan</p>
                                <p>
                                  <span className="Procedure-text">
                                    Procedure:
                                  </span>{" "}
                                  <span className="Cataract-text">
                                    {" "}
                                    Cataract
                                  </span>
                                </p>
                                <p>
                                  <span className="Procedure-text">
                                    Status:
                                  </span>{" "}
                                  <span className="Cataract-text">
                                    {" "}
                                    O.D. Pre-OP
                                  </span>
                                </p>
                              </div>
                            </div> */}
                              </div>
                            </div>
                          </>
                        ) : (
                          <h5 className="text-center mt-5 mb-5">
                            No Documents Found...
                          </h5>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Dashboard;
