
import { callAPI } from "../config/api-core";
import { newJourneyListAPIMethods } from "../methods/new-journey-list-api-method";

const newJourneyListAPI = async () => {
  let userToken = localStorage.getItem("token");
  let token = JSON.parse(userToken);
  let header = {
    // "content-type": "multipart/form-data",
    Authorization: userToken && `Bearer ${token}`,
  };
  const response = await callAPI(
    newJourneyListAPIMethods,
    true,
    {},
    header,
    false,
    false
  );
  return response;
};

export const newJourneyList = () => newJourneyListAPI();
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  newJourneyList,
};
