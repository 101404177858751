import React from "react";
import "../assets/css/newsidebar.css";
import { Outlet, NavLink, Link } from "react-router-dom";
import { useMyPatient } from "../pages/Mypatient/custom-hook/my-patient-hook";

const NewSidebar = () => {

  return (
    <>
      {/* <div className="collapse navbar-collapse Newheader-navbar-items" id="navbarSupportedContent"> */}
      <div className="Newsidebar-div mv_sidebar">
        <ul className="navbar-nav">
          <li className="nav-item nav-list">
            <NavLink to="/dashboard" className="nav-linking ">
              <i className="fa fa-house-chimney"></i>
              <span className="newheader-span">Dashboard</span>
            </NavLink>
          </li>
          <li className="nav-item nav-list">
            <NavLink to="/mypatient" className="nav-linking "  >
              <i className="fa-regular fa-user"></i>
              <span className="newheader-span">My Patients</span>
            </NavLink>
          </li>

          <li className="nav-item nav-list">
            <NavLink to="/documents" className="nav-linking">
              <i className="fa fa-file"></i>
              <span className="newheader-span">Documents</span>
            </NavLink>
          </li>

          <li className="nav-item nav-list" style={{ position: "relative"}}>
            <NavLink to="/notification" className="nav-linking">
              <i className="fa-regular fa-bell"></i>
              <span className="newheader-span">Notifications</span>
              {/* <div style={{width: "10px", height: "10px", background: "red", borderRadius: "100%" , position: "absolute", top: "0", right: "32px"}}></div> */}
            </NavLink>
          </li>
        </ul>
      </div>
      {/* </div> */}
      <div>
        <Outlet/>
      </div>
    </>
  );
};

export default NewSidebar;
