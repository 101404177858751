import { useContext, useEffect, useRef, useState } from "react";
import api from "../../../services/api/dashboard-api";
import { UserContext } from "../../../App";
import { useLogin } from "../../login/custom-hook/login-hook";

export const useDashboard = () => {
    const [documents, setDocuments] = useState([]);
    const [patientsList, setPatientsList] = useState([]);
    const [totalPatients, setTotalPatients] = useState("");
    const [totalDocuments, setTotalDocuments] = useState("");
    const [recentDocuments, setRecentDocuments] = useState([]);
    const [recentPatientsList, setRecentPatientsList] = useState([]);
    const [doctorName, setDoctorName] = useState("");
    const [loading, setLoading] = useState(false);

    const hasMounted = useRef(false);
    const { profile, setProfile } = useContext(UserContext);
    const { handleError } = useLogin();

    useEffect(() => {
        if (!hasMounted.current) {
            dashboardAPI();
            let docName = JSON.parse(localStorage.getItem("user"));
            if (docName) {
                setDoctorName(docName.name);
            }
            hasMounted.current = true;
        }
    }, []);

    const dashboardAPI = async() => {
        setLoading(true);
        try {
            const response = await api.getDashboardAPI();
            // console.log("Response", response);
            if (response && response.data.data) {
                setDocuments(response.data.data.documents_list);
                setPatientsList(response.data.data.patients_list);
                setTotalPatients(response.data.data.total_patients);
                setTotalDocuments(response.data.data.total_documents);
                setProfile({
                    profileImg: response.data.data.docter_data.image,
                    profileName: response.data.data.docter_data.name,
                    profileId: response.data.data.docter_data.id,
                    profileEmail: response.data.data.docter_data.email,
                    profilePhone: response.data.data.docter_data.phone_number,
                });
                setLoading(false);
            } else {}
        } catch (err) {
            console.log(err);
            if (err.response.status == 401) {
                handleError();
                setLoading(false);
            }
        }
    };

    return {
        documents,
        patientsList,
        totalPatients,
        totalDocuments,
        recentDocuments,
        recentPatientsList,
        doctorName,
        loading,
        profile,
        dashboardAPI,
    };
};