import Offcanvas from "react-bootstrap/Offcanvas";
import img1 from "../assets/img/back-arrow-icon.svg";
import img2 from "../assets/img/file-upload-icon.svg";
import { Link } from "react-router-dom";
import { memo, useMemo, useState } from "react";
import api from "../services/api/update-journey-details-api";
import { useEffect } from "react";
import img3 from "../assets/img/red-close-icon.svg";
import ToastNotification from "./toast-notification";
import "../assets/css/newjourney-detail.css";
import { useMyPatient } from "../pages/Mypatient/custom-hook/my-patient-hook";
import pdfIcon from "../assets/img/pdf-img.png";
import docIcon from "../assets/img/doc-logo.png";

const JourneyDocuments = ({
  notes,
  documents,
  showJourneyDetail,
  setShowJourneyDetail,
  journeyId,
  patientId,
  previewUrls,
  newDocuments,
  setPreviewUrls,
  setNewDocuments,
  setMsg,
  msg,
  showDocToast,
  setShowDocToast,
  messages,
  channels,
  sendMessage,
  journeyName
}) => {
  // console.log("newjourney component rendered");
  // console.log("journeyId", journeyId);
  // console.log("patientId", patientId);
  // console.log("notes", notes);
  // console.log("documents", documents);
  const [pubnubDocuments, setPubnubDocuments] = useState([]);

  const value = useMemo(() => {
    let arr = [];
    if (messages) {
      for (const element of messages) {
        arr.unshift(element);
      }
      setPubnubDocuments(arr);
    }
  }, [messages]);

  const updateJourneyDetailsAPI = async (request) => {
    try {
      const response = await api.updateJourneyDetails(request);
      // console.log("Response", response);
      if (response && response.data.status === 1) {
        setPreviewUrls([]);
        setMsg(response.data.message);
        setShowDocToast(true);
        // journeyAPI({ patient_id: patientId });
        let publishPayload;
        if (newDocuments.length > 0) {
          publishPayload = {
            title: "Add documents by doctor",
            description: response.data.data.document.message,
            created_at: response.data.data.document.created_at,
            type: "add_documents",
            documents_count: response.data.data.document.documents_count,
            journey_id: journeyId,
            journey_name: journeyName,
            patient_id: patientId,
            uploadedImages: response.data.data.document.uploadedImages,
          };
          sendMessage(publishPayload);
        }

        setNewDocuments([]);
      } else {
        setPreviewUrls([]);
        setNewDocuments([]);
        setMsg(response.data.message);
        setShowDocToast(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFileSelect = (e) => {
    const newFiles = [...newDocuments, ...e.target.files];
    setNewDocuments(newFiles);

    const newPriviewUrls = [];
    for (let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i];
      // console.log("file", file);
      // console.log("filetype", file.type);
      if (
        file.type !== "application/pdf" &&
        file.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && 
          file.type != "application/msword"
      ) {
        const objectUrl = URL.createObjectURL(file);
        newPriviewUrls.push(objectUrl);
      } else if (
        file.type ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type == "application/msword"
      ) {
        newPriviewUrls.push(docIcon);
      } else {
        newPriviewUrls.push(pdfIcon);
      }
    }
    setPreviewUrls(newPriviewUrls);
  };

  const handleDelete = (index) => {
    const newFiles = [...newDocuments];
    newFiles.splice(index, 1);

    setNewDocuments(newFiles);
    const newPreviewUrls = [...previewUrls];
    newPreviewUrls.splice(index, 1);
    setPreviewUrls(newPreviewUrls);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let doc = [...documents, ...newDocuments];
    let params = {
      patient_id: patientId,
      journey_id: journeyId,
      documents: doc,
      journey_name: journeyName,
    };
    updateJourneyDetailsAPI(params);
  };

  return (
    <>
      <Offcanvas
        className="journey-detail"
        show={showJourneyDetail}
        onHide={() => setShowJourneyDetail(false)}
        placement="end"
        backdrop="static"
        style={{ width: "467px", borderLeft: "1px solid #f5f2f2" }}
      >
        <Offcanvas.Header>
          <Offcanvas.Title>
            <h2 className="nav nav-h2 nav-journey">
              {" "}
              <span
                className="icon journey-icon"
                onClick={() => {
                  setShowJourneyDetail(false);
                  setPreviewUrls([]);
                  setNewDocuments([]);
                }}
              >
                <img src={img1} alt="" />{" "}
              </span>
              Journey Documents
            </h2>
            {showDocToast ? (
              <ToastNotification
                content={msg}
                setShow={setShowDocToast}
                show={showDocToast}
              />
            ) : null}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form id="dropFileForm">
            <div className="journey_page_div">
              {newDocuments.length > 0 ? (
                <button
                  type="button"
                  className="btn  me-1 btnn-savee"
                  onClick={(e) => handleSubmit(e)}
                >
                  Save
                </button>
              ) : null}
            </div>

            <h6 className="mt-4 text-left">Recent Documents</h6>
            <div className="recent-document_div">
              <ul className="patient-cards-lists d-flex flex-wrap recent-documents-div p-0 m-0">
                {pubnubDocuments.length > 0
                  ? pubnubDocuments.map((item, index) => {
                      // console.log("apiitem", item);
                      // console.log(item.message.journey_id == journeyId);
                      // console.log("pubnubid", item.message.journey_id);
                      // item.message.journey_id == journeyId ?
                      return (
                        <>
                          {item.channel === channels[0] &&
                            item.message.patient_id == patientId &&
                            item.message.journey_id == journeyId &&
                            item.message.type === "add_documents" &&
                            item.message.uploadedImages?.map(
                              (newImg, index) => {
                                // console.log("newImg", newImg);
                                return (
                                  <li
                                    key={index}
                                    className="mv__recent_document"
                                  >
                                    <div className="recent_document-img mv__recent_document-img">
                                      {newImg.extension === "doc" ||
                                      newImg.extension === "docx" ? (
                                        <img
                                          src={docIcon}
                                          alt=""
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                          }}
                                        />
                                      ) : (
                                        // <p
                                        //   className="ps-2 "
                                        //   style={{
                                        //     width: "100%",
                                        //     height: "100%",
                                        //     marginTop: "49px",
                                        //   }}
                                        // >
                                        //   {newImg.extension}
                                        // </p>
                                        <img
                                          src={
                                            newImg.extension === "pdf"
                                              ? pdfIcon
                                              : newImg.url
                                          }
                                          alt=""
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                          }}
                                        />
                                      )}
                                      {/* <img
                                        src={newImg.url}
                                        alt=""
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />{" "} */}
                                    </div>

                                    <span className="close-btn"></span>
                                  </li>
                                );
                              }
                            )}
                        </>
                      );
                    })
                  : null}
                {documents.length > 0
                  ? documents.map((item, index) => {
                      // console.log("docc", item);
                      return (
                        <li key={index} className="mv__recent_document">
                          <div className="recent_document-img mv__recent_document-img">
                            {item.document_extension === "doc" ||
                            item.document_extension === "docx" ? (
                              <img
                              src={
                               docIcon
                              }
                              alt=""
                              style={{ width: "100%", height: "100%" }}
                            />
                            ) : (
                              <img
                                src={
                                  item.document_extension === "pdf"
                                    ? pdfIcon
                                    : item.document_path
                                }
                                alt=""
                                style={{ width: "100%", height: "100%" }}
                              />
                            )}
                          </div>

                          <span className="close-btn"></span>
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>

            <h6 className="mt-3 text-left">Scanned/ Uploaded Documents</h6>
            <div classname="uploaded-document_div">
              <ul
                className="patient-cards-lists d-flex flex-wrap"
                style={{ listStyleType: "none" }}
              ></ul>
              <ul className="journey-div mv__journey_box">
                {previewUrls &&
                  previewUrls.map((url, index) => {
                    // console.log("url", url);
                    return (
                      <li
                        className="journey-list-li  mv__journey_list"
                        key={index}
                        style={{
                          listStyleType: "none !important",
                          width: "30%%",
                        }}
                      >
                        <div className="journey-img-holder">
                          <img
                            src={url}
                            alt=""
                            style={{ width: "100%", height: "100%" }}
                          />{" "}
                          <span
                            className="close-btn"
                            onClick={() => handleDelete(index)}
                          >
                            <img src={img3} alt="" />
                          </span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="file-upload-btn">
              <input
                type="file"
                name="newDocuments"
                id="fileInput"
                multiple
                onChange={(e) => handleFileSelect(e)}
              />

              <label htmlFor="fileInput" id="fileLabel">
                <img className="mb-3" src={img2} alt="" />
                <span id="fileLabelText">Drap & drop a file here or click</span>
                <span id="uploadStatus"></span>
              </label>
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default memo(JourneyDocuments);
